// Generated by Framer (3def70c)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
import { Icon as Phosphor1 } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
import * as localizedValues from "./CTyE9U_2n-0.js";
const PhosphorFonts = getFonts(Phosphor);
const Phosphor1Controls = getPropertyControls(Phosphor1);

const cycleOrder = ["n_xYnYOK2", "kqrNbu5Vw"];

const serializationHash = "framer-CMrxc"

const variantClassNames = {kqrNbu5Vw: "framer-v-c6nxg8", n_xYnYOK2: "framer-v-1pwba7c"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Default: "n_xYnYOK2", Select: "kqrNbu5Vw"}

const getProps = ({height, id, phosphorIcon, width, ...props}) => { return {...props, Srw2tSrn7: phosphorIcon ?? props.Srw2tSrn7 ?? "House", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "n_xYnYOK2"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;phosphorIcon?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Srw2tSrn7, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "n_xYnYOK2", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1pwba7c", className, classNames)} data-border data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"n_xYnYOK2"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(82, 116, 255, 0)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-cb8c315f-aa79-46d0-9894-796d4f3c486e, rgb(248, 248, 248))", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, boxShadow: "inset 0px 3px 4px 0px rgba(255, 255, 255, 0.1)", ...style}} variants={{kqrNbu5Vw: {"--border-bottom-width": "0.5px", "--border-color": "var(--token-e786d274-1fd4-44b2-8bb2-53d1a18b7911, rgb(82, 116, 255))", "--border-left-width": "0.5px", "--border-right-width": "0.5px", "--border-top-width": "0.5px"}}} {...addPropertyOverrides({kqrNbu5Vw: {"data-framer-name": "Select"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-1jzc775-container"} layoutDependency={layoutDependency} layoutId={"bauocSPx3-container"}><Phosphor color={"var(--token-fe9ded06-f608-4919-9137-825f8ed57e50, rgb(125, 125, 125))"} height={"100%"} iconSearch={"House"} iconSelection={Srw2tSrn7} id={"bauocSPx3"} layoutId={"bauocSPx3"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"} {...addPropertyOverrides({kqrNbu5Vw: {color: "var(--token-e786d274-1fd4-44b2-8bb2-53d1a18b7911, rgb(82, 116, 255))"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-CMrxc.framer-1f2zm10, .framer-CMrxc .framer-1f2zm10 { display: block; }", ".framer-CMrxc.framer-1pwba7c { height: 24px; overflow: visible; position: relative; width: 24px; }", ".framer-CMrxc .framer-1jzc775-container { bottom: 4px; flex: none; left: 4px; position: absolute; right: 4px; top: 4px; }", ".framer-CMrxc[data-border=\"true\"]::after, .framer-CMrxc [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"kqrNbu5Vw":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Srw2tSrn7":"phosphorIcon"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerCTyE9U_2n: React.ComponentType<Props> = withCSS(Component, css, "framer-CMrxc") as typeof Component;
export default FramerCTyE9U_2n;

FramerCTyE9U_2n.displayName = "Category Elements/Category Icon Copy";

FramerCTyE9U_2n.defaultProps = {height: 24, width: 24};

addPropertyControls(FramerCTyE9U_2n, {variant: {options: ["n_xYnYOK2", "kqrNbu5Vw"], optionTitles: ["Default", "Select"], title: "Variant", type: ControlType.Enum}, Srw2tSrn7: Phosphor1Controls?.["iconSelection"] && {...Phosphor1Controls["iconSelection"], defaultValue: "House", description: undefined, hidden: undefined, title: "Phosphor Icon"}} as any)

addFonts(FramerCTyE9U_2n, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})